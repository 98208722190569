module services {
    export module master {
        export class routeLeadTimesService implements interfaces.master.IRouteLeadTimesService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            //Gets list of all pack Configuration for a dropdown/autocomplete list
            getByRoute(): ng.resource.IResourceClass<interfaces.master.IRouteLeadTimes> {

                return this.$resource<interfaces.master.IRouteLeadTimes>(this.ENV.DSP_URL + "RouteLeadTimes/GetByRoute",
                {
                    routeId: '@routeId',
                    shippingPriorityId: '@shippingPriorityId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: false
                        }
                    });
            }
        }
    }
    angular.module("app").service("routeLeadTimesService", services.master.routeLeadTimesService);
}